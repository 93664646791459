.dropdown {
	display: flex;
	position: relative;
	padding-top: 5px;
	padding-bottom: 5px;

	.custom-scroll-dropdown-parent {

		&.fixed-height {
			height: 200px;
		}
	}

	&--label {
		display: flex;
		align-items: center;
		cursor: pointer;

		> .Icon {
			min-width: 24px;
			display: flex;
			justify-content: center;
			margin-left: 4px;
		}
	}

	&--avatar {
		width: 28px;
		min-width: 28px;
		height: 28px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	&--items {
		transform: translateY(10px);
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-in-out;
		z-index: 5;
		position: absolute;
		top: 100%;
		right: -14px;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		border: 1px solid #d4dee7;
		background: #fff;
		border-radius: 4px;

		&:before, &:after {
			content: '';
			position: absolute;
			bottom: 100%;
			right: 25px;
			border: solid transparent;
			height: 0;
			width: 0;
			pointer-events: none;
		}

		&:before {
			border-width: 6px;
			margin: 0 -6px;
			border-bottom-color: #d4dee7;
		}

		&:after {
			border-width: 5px;
			margin: 0 -5px;
			border-bottom-color: #fff;
		}
	}

	&--item {
		height: 30px;
		display: flex;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #000;
		text-decoration: none;
		white-space: nowrap;
		cursor: pointer;

		&:first-child {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:hover {
			background: #E5EBF1;
		}

		&.selected {
			background: #5181b8;
			color: #fff;
		}

		&-last {
			position: relative;
			margin-top: 8px;
			height: 30px;
			display: flex;
			align-items: center;
			padding-left: 12px;
			padding-right: 15px;
			text-decoration: none;
			white-space: nowrap;
			color: #2a5885;
			cursor: pointer;
			border-top: 1px solid #e3e4e8;
		}
	}

	&--public {
		height: 44px;

		&.dropdown--item-last {
			margin-top: 0;

			&:before {
				top: 0;
			}
		}

		.dropdown--avatar {
			margin-right: 8px;
		}

		.Icon--add_16 {
			margin-left: 9px;
			margin-right: 15px;
		}
	}
}

.avatar-action {
	margin-left: auto;

	.dropdown {
		padding-top: 9px;
		padding-bottom: 9px;
	}

	.dropdown--items {

		&:before, &:after {
			right: 55px;
		}
	}

	&.opened {

		.dropdown--items {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
			transform: translateY(0);
		}
	}
}

.Avatar__shadow {
	display: none;
}
