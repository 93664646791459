.block-connect {
	padding: 25px;
	display: flex;
	background-color: #fff;

	+ .tabs-header {
		border-top: none;
	}

	&--image {
		margin-right: 25px;
		width: 140px;
		height: 140px;
		border-radius: 8px;
		background-image: url("../img/public-pic.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	&--info {
		max-width: 440px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&--title {
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.32px;
		color: #000000;
	}

	&--text {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.08px;
		color: #000000;
	}

	&--btn {
		margin-top: 20px;
		display: flex;
	}

	.button-with-loader + .Button{
		margin-left: 15px;
	}

	.Button--lvl-tertiary {
		padding: 0;
	}
}
