.modal {
	
	&--open {
		overflow: hidden;
	}
	
	&--overlay {
		background-color: rgba(0, 0, 0, 0.7);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		overflow: auto;
	}
	
	&--content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
		max-height: 100%;
		color: #fff;
		font-family: SFProD;
		
		.Button {
			cursor: pointer;
		}
		
		> div {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	
	&--close {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}
}