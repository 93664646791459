.form-label {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	> label:first-child {
		margin-bottom: 12px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.08px;
		color: #222222;
	}
}