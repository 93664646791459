.wizard {
	display: flex;
	margin: 25px 25px 0;
	padding-bottom: 25px;
	border-bottom: 1px solid #e7e8ec;
	border-radius: 0.5px;

	&--image {
		margin-right: 25px;
		min-width: 140px;
		width: 140px;
		height: 140px;
		border-radius: 8px;
		background-image: url("../img/public-pic.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	&--info {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&--title {
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.32px;
		color: #000000;
	}

	&--subtitle {
		max-width: 440px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.4;
		letter-spacing: -0.08px;
		color: #000000;

		p {
			margin: 0;
		}
	}

	&--footer {
		margin-top: 64px;
		margin-bottom: 64px;
		display: flex;
		justify-content: center;
	}

	&--choose {
		padding-top: 28px;
		padding-bottom: 48px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&--list {
		margin-bottom: 40px;
		width: 304px;

		> button {
			margin-top: 8px;
			margin-left: 47px;
			margin-bottom: 10px;
		}
	}

	&--back {
		margin-top: 25px;
		margin-left: 25px;
		margin-bottom: 8px;
		display: inline-block;
		cursor: pointer;

		&-content {
			display: flex;
			align-items: center;

			span {
				margin-left: 5px;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: -0.15px;
				color: #656565;
			}
		}
	}

	&--avatar {
		margin-right: 15px;
		width: 112px;
		min-width: 112px;
		height: 112px;
		border-radius: 50%;
	}

	&--type {
		padding-top: 32px;
		padding-bottom: 48px;
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			margin-bottom: 48px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.button-with-loader {
			margin-bottom: 0;
		}

		span {
			margin-bottom: 6px;
			display: block;
			font-size: 13px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.08px;
			color: #222222;
		}

		.Radio {
			padding: 6.5px 0;

			&__icon {
				margin-right: 8px;
				width: 15px;
				height: 15px;
				border: 1px solid #c1c9d1;
			}

			&__input:checked~.Radio__container .Radio__icon {
				border-color: #5181b8;

				&:after {
					background-color: #5181b8;
					width: 7px;
					height: 7px;
					top: 3px;
					left: 3px;
				}
			}

			&__content {
				font-size: 13px;
				line-height: 1;
			}
		}
	}
}
