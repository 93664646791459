.welcome {
	padding: 41px 32px 48px 32px;
	font-family: SFPro;

	&--center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&--image {
		width: 350px;
		height: 280px;
		border-radius: 8px;
		background-image: url("../img/welcome-pic.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	&--title {
		margin-top: 47px;
		margin-bottom: 24px;
		font-family: SFProD;
		font-size: 22px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.35px;
		text-align: center;
		color: #222222;
	}

	&--subtitle {
		margin-bottom: 48px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -0.32px;
		text-align: center;
		color: #000000;
	}

	.Checkbox {
		margin-bottom: 12px;
		padding: 0;
	}

	.Checkbox__icon {
		margin-top: 2px;
		margin-right: 8px;
		width: 15px;
		height: 15px;
		border-radius: 3px;
	}

	.Checkbox__content {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.54;
		letter-spacing: -0.26px;

		a {
			color: #2a5885;
		}
	}

	.Icon--done_16 {
		width: 10px!important;
		height: 10px!important;

		svg {
			width: 10px!important;
			height: 10px!important;
		}
	}
}

.home-zero {
	border: 1px solid #e3e4e8;
	border-radius: 4px;
	padding: 20px;

	.welcome-one--subtitle {
		margin-bottom: 24px;
	}

	.UsersStack {
		margin-top: 36px;
	}
}
