.block-head {
	width: 100%;
	font-family: SFPro;
	border: 1px solid #e3e4e8;
	border-radius: 4px;
	background: #fff;
	box-sizing: border-box;

	& > .header {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: normal;
		color: #000000;
	}

	&-main {
		width: 100%;
		font-family: SFPro;
		border: 1px solid #e3e4e8;
		border-radius: 4px;
		background: #fff;
		box-sizing: border-box;

		+ .block-head {
			margin-top: 12px;
		}

		.tabs-header {
			margin-left: 20px;
			margin-right: 20px;
			border-left: none;
			border-right: none;
			width: auto;

			.TabsItem__in {
				padding: 15px 6px;
			}
		}

		.header {
			background: #fafbfc;
			border-radius: 4px 4px 0 0;
			height: 54px;
			border-bottom: 1px solid #e7e8ec;

			span {

				b {
					font-size: 16px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: normal;
					color: #000000;
				}
			}
		}
	}
}

.header {
	height: 55px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;

	+ .Tabs.tabs-header {
		border-top: none;
	}

	> span {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: normal;
		color: #000000;
	}

	&--rating {
		display: flex;
		align-items: center;
		margin-left: 8px;
	}

	&-action {
		display: flex;
		margin-left: auto;

		&--label {
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.08px;
			color: #2a5885;
			cursor: pointer;

			.Icon {
				margin-left: 10px;
			}
		}
	}
}

.block-head--footer {
	height: 45px;
	border-top: 1px solid #e7e8ec;
	display: flex;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;

	.pagination span.active:before {
		content: '';
		position: absolute;
		top: -16px;
		left: 0;
		right: 0;
		height: 2px;
		background: #5181b8;
		pointer-events: none;
	}
}
