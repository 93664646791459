.marathon-about {
	padding: 20px 20px 0;

	&--notification {
		margin-bottom: 20px;
		background: #f0f2f5;
		border-radius: 4px;
		padding: 10px 15px 12px;
		display: flex;
		flex-direction: column;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.08px;
		color: #000000;

		b {
			line-height: 1.54;
		}
	}

	&--cover {
		width: 100%;
		height: 172px;
		border-radius: 4px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&--content {
		display: flex;
		flex-direction: column;
		width: calc(100% - 367px - 20px);
	}

	&--profile {
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	&--title {
		margin-bottom: 4px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.32px;
		color: #000000;
	}

	&--subtitle {
		margin-top: 4px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #000000;
	}

	&--rating {
		margin-top: 8px;
		display: flex;
		align-items: center;

		span {
			margin-left: 14px;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: -0.08px;
			color: #939393;
		}
	}

	&--video {
		width: 367px;
		height: 212px;
		border-radius: 4px;
		overflow: hidden;
	}
}

.marathon--action {
	display: flex;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: -0.08px;
	color: #939393;

	.dropdown {
		padding-bottom: 5px;
		margin-bottom: -5px;

		&--items {
			padding-top: 4px;
			right: auto;
			left: 0;

			&:before, &:after {
				right: 25px;
			}
		}

		&--item {
			border-radius: 0;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.08px;
			color: #2a5885;
		}
	}
}

.marathon-public {
	margin-left: -20px;
	margin-right: -20px;
	padding: 15px 20px 15px 20px;
	border-top: 1px solid #e7e8ec;
	display: flex;
	align-items: center;

	&--avatar {
		width: 50px;
		min-width: 50px;
		height: 50px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	&--info {
		margin-left: 15px;
		display: flex;
		flex-direction: column;
		max-width: calc(100% - 65px);

		a {
			text-decoration: none;
		}
	}

	&--name {
		margin-bottom: 3px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.08px;
		color: #2a5885;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--stats {
		font-size: 12.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #939393;
	}
}

.zero {
	min-height: 195px;
}

.add-step-block {
	margin-left: 20px;
	margin-right: 20px;
	border-bottom: 1px solid  #edeef0;
	padding-top: 9px;
	padding-bottom: 9px;
	margin-bottom: 12px;

	.btn-add-step {
		padding: 0;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #2a5885;

		.Icon {
			margin-right: 15px;
		}
	}
}
