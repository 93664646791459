.rating-stars {
	display: flex;
	
	>div + div {
		margin-left: 3px;
	}
	
	.icon--active {
		path {
			fill: #FFD300;
			stroke: #FFD300;
		}
	}
	
	&.select {
		
		.icon svg path {
			fill: #aaaeb3;
			stroke: #aaaeb3;
		}
	}
	
	.white {
		&.icon--active path {
			fill: #fff;
			stroke: #fff;
		}
		path {
			fill: rgba(#fff, .4);
			stroke: rgba(#969696, .4);
		}
	}
}

.rating-review {
	display: flex;
	
	.rating-stars {
		margin-left: 0;
	}
	
	&--amount {
		font-size: 13px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #76787a;
		color: var(--text_subhead);
		margin-left: 7px;
	}
}