.modal-load {
	width: 654px;
	overflow: hidden;
	color: #000;
	font-family: SFPro;

	.modal--close {
		position: relative;
	}

	&--header{
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 54px;
		border-radius: 4px 4px 0 0;
		padding: 0 25px;
		background-color: #f0f2f5;
		letter-spacing: -0.15px;
		font-size: 14px;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&--body {
		border-radius: 0 0 4px 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #fff;
		padding: 48px 25px;

		&.error {
			padding-top: 25px;
		}
	}

	&--text {
		margin-bottom: 32px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.46;
		letter-spacing: -0.08px;
		text-align: center;
		color: #000000;
	}

	&--error {
		margin-bottom: 48px;
		padding: 10px 15px;
		border-radius: 4px;
		background-color: #ffd6cc;
		display: flex;
		flex-direction: column;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.08px;
		color: #000000;
	}
}

.modal--hint {
	margin-top: 23px;
	margin-bottom: 23px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: -0.08px;
	text-align: center;
	color: #909499;
	color: var(--text_secondary);
}

.hide {
	display: none;
}
