.radio-icon {
	padding: 4px 15px 4px 12px;

	.Radio {
		padding: 4px 15px 4px 12px;
		display: inline-block;
		width: 100%;
		box-sizing: border-box;

		&__container {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
		}

		&__input:checked~.Radio__container .Radio__icon {
			border-color: #5181b8;

			&:after {
				background: #5181b8;
			}
		}

		&__icon {
			width: 15px;
			height: 15px;
			border: 1px solid #c5d0db;
			border-radius: 50%;
			margin: 0;

			&:after {
				top: 3px;
				left: 3px;
				width: 7px;
				height: 7px;
			}
		}

		&__content {
			display: flex;
			align-items: center;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: -0.15px;
			color: #000000;
			width: calc(100% - 15px);
			padding-right: 10px;
			box-sizing: border-box;

			& > div {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&--icon {
		background-repeat: no-repeat;
		background-size: contain;
		width: 40px;
		min-width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 12px;
	}
}
