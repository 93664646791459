.marathon-item {
	margin-bottom: 15px;
	padding-right: 30px;
	display: flex;
	cursor: pointer;
	padding-bottom: 15px;
	border-bottom: 1px solid #e7e8ec;

	&:last-child {
		padding-bottom: 20px;
		margin-bottom: 0;
		border: 0;
	}

	&--header {
		height: 55px;
		display: flex;
		align-items: center;
	}

	&--image {
		margin-right: 15px;
		width: 112px;
		min-width: 112px;
		height: 112px;
		border-radius: 8px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&--amount {
		position: absolute;
		right: 13px;
		bottom: 12px;
		padding: 2px 4px 1px;
		border-radius: 4px;
		background-color: rgba(#000, .4);
		font-size: 11px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.07px;
		color: #ffffff;
	}

	&--content {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&--info {
		display: flex;
		flex-direction: column;
	}

	&--title {
		margin-bottom: 6px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #222222;
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		&.one-row {
			-webkit-line-clamp: 1;
		}
	}

	&--description {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #656565;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&--footer {
		margin-top: 7px;
		min-height: 16px;
		display: flex;
		flex-direction: column;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #656565;
	}

	&--desc {
		margin-bottom: 8px;
	}

	&--icon {
		color: #aaaeb3;

		> .icon {
			cursor: pointer;
		}
	}

	&--list {
		display: flex;
		flex-wrap: wrap;

		&-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
	}
}

.zero {
	min-height: 275px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&--fixed-height {
		height: 465px;
	}

	&--title {
		margin-top: 25px;
		margin-bottom: 24px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.08px;
		text-align: center;
		color: #000000;
	}

	& button {
		margin-bottom: 45px;
	}
}
