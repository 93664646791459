.test {

	&--inner {
		padding: 25px 20px 20px;
		border-bottom: 1px solid #e7e8ec;

		.button-with-loader {
			margin-top: 20px;
		}
	}

	&--block {
		position: relative;
		max-width: 400px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		.Button--lvl-tertiary {
			padding: 0;

			.Icon {
				margin-right: 11px;
			}
		}

		.form-group + .Button {
			margin-top: 20px;
		}
	}

	&--input {
		display: flex;
		align-items: center;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		.Checkbox {
			margin-left: 12px;
		}

		.input, input {
			max-width: 286px;
		}
	}

	&--pairs {
		display: flex;
		justify-content: space-between;
		width: 100%;

		> * {
			max-width: calc(50% - 4px);
			width: calc(50% - 4px);
		}

		&+& {
			margin-top: 12px;
		}
	}

	&--footer {
		padding-top: 15px;
		padding-bottom: 15px;
		display: flex;
		justify-content: center;

		.Button + .Button {
			margin-left: 12px;
		}
	}

	&--add-question {
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #e7e8ec;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #2a5885;
	}
}

.delete-question {
	position: absolute;
	top: -4px;
	right: -4px;
	padding: 4px;
	cursor: pointer;
}

.input {
	position: relative;
	width: 100%;

	input {
		padding-right: 32px;
	}

	&:hover .input--close{
		opacity: 1;
		pointer-events: all;
	}

	&--close {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4px;
		padding: 4px;
		cursor: pointer;
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-in-out;

		&:hover .input--tooltip {
			opacity: 1;
			pointer-events: all;
		}
	}

	&--tooltip {
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-in-out;
		position: absolute;
		left: -9px;
		bottom: calc(100% + 9px);
		white-space: nowrap;
		padding: 4px 9px;
		background: rgba(#0e0e0e, .72);
		border-radius: 4px;
		font-size: 12.5px;
		color: #ffffff;

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 16px;
			border: solid transparent;
			height: 0;
			width: 0;
			pointer-events: none;
		}
		&:before {
			border-width: 5px;
			border-top-color: rgba(#0e0e0e, .72);
		}
	}
}

.button-with-loader + button {
	margin-left: 15px;
}
