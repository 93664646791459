.course-create {
	
	&--content {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		width: 100%;
		padding: 25px 25px 20px 25px;
	}
	
	&--bottom {
		border-top: 1px solid #e7e8ec;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}