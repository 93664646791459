.Button__before .icon{
	margin-right: 8px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon12 {
	width: 12px;
	height: 12px;
}

.icon14 {
	width: 14px;
	height: 14px;
}

.icon16 {
	width: 16px;
	height: 16px;
}

.icon18 {
	width: 18px;
	height: 18px;
}

.icon20 {
	width: 20px;
	height: 20px;
}

.icon24 {
	width: 24px;
	height: 24px;
}

.icon28 {
	width: 28px;
	height: 28px;
}

.icon40 {
	width: 40px;
	height: 40px;
}

.icon56 {
	width: 56px;
	height: 56px;
}