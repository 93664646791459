.pagination {
    margin-left: auto;
    display: flex;
    margin-right: 4px;

    span {
        position: relative;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.15px;
        color: #939393;
        text-align: center;
        cursor: pointer;

        &.active {
            color: #000;
        }
    }

    &--profile {
        position: absolute;
        top: 17px;
        right: 0;

        span:hover:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -12px;
            height: 2px;
            background: #dae1e8;
            pointer-events: none;
        }

        span.active:before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -12px;
            height: 2px;
            background: #5181b8;
            pointer-events: none;
        }


    }

    .no-pointer {
        cursor: default;
    }
}
