.breadcrumbs {
	display: flex;
	align-items: center;
	width: calc(100% - 80px);
	overflow: hidden;

	&--item {
		position: relative;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.32px;
		color: #656565;
		cursor: pointer;

		span {
			white-space: nowrap;
		}

		& + & {
			padding-left: 13px;
			margin-left: 7px;

			&:before{
				content: '›';
				position: absolute;
				top: 2px;
				left: 0;
				color: #656565;
				font-size: 16px;
				line-height: 1;
			}
		}

		&:last-child {
			color: #000;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			pointer-events: none;
		}
	}

	&--active{
		color: #000000;
	}
}
