.button-with-loader {

    .button {
        background-color: #5181b8;
        border-radius: 4px;
        cursor: pointer;
        padding: 7px 16px;
        display: inline-block;
        box-sizing: border-box;
        outline: 0;
        text-decoration: none;
        border: 1px solid transparent;
        margin: 0;
        -webkit-user-select: none;
        user-select: none;
        font-size: 12.5px;
        line-height: 14px;
        white-space: nowrap;
        color: #fff;

        .points {
            display: inline-flex;

            .point {
                display: inline-block;
                vertical-align: top;
                width: 4px;
                height: 4px;
                background-color: #e0e8f1;
                border-radius: 50%;
                margin-right: 2px;
                opacity: 0.2;
                animation-duration: 750ms;
                animation-name: points-anim;
                animation-iteration-count: infinite;
            }

            .point:nth-child(1) {
                animation-delay: 0ms;
            }

            .point:nth-child(2) {
                animation-delay: 180ms;
            }

            .point:nth-child(3) {
                animation-delay: 360ms;
            }
        }

        &.secondary {
            color: #5c6c80;
            background: #e5ebf1;

            .point {
                background-color: #45688e;
            }
        }

        &:active {
            opacity: 0.5;
        }

        &.is-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

@keyframes points-anim {
    0% {
        opacity: 0.2;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
