.program {
	
	&--list {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}
	
	&--item {
		display: flex;
		flex-direction: column;
		
		&:not(:last-child) {
			margin-bottom: 12px;
			padding-bottom: 10px;
			border-bottom: 1px solid #e7e8ec;
		}
	}
	
	&--top {
		display: flex;
		align-items: baseline;
	}
	
	&--title {
		margin-right: 15px;
		flex-grow: 1;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #222222;
	}
	
	&--bottom {
		margin-top: 6px;
		display: flex;
		align-items: center;
		font-size: 12.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.52;
		letter-spacing: -0.08px;
		color: #2a5885;
		
		div {
			cursor: pointer;
		}
		
		span {
			margin-left: 3px;
			margin-right: 3px;
			color: #939393;
		}
	}
}