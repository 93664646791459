.video-block {
	position: relative;
	width: 100%;
	height: 228px;

	.Icon--16 {
		position: absolute;
		top: 4px;
		right: 4px;
		opacity: 0.64;
		border-radius: 2px;
		background-color: #000000;
		cursor: pointer;
		z-index: 0;
	}

	video {
		width: 100%;
		height: 100%;
		background-color: #e6ecf1;
	}
}

input.error {
	background-color: #f9ebea;

	& + .error-text{
		display: block;
	}
}

.error-text {
	display: none;
	margin-top: 6px;
	font-size: 12.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #db3b3b;
}
