@font-face { font-family: "SFPro"; font-weight: 400; font-style: normal; src: url("../font/SFProText-Regular.ttf"); }
@font-face { font-family: "SFPro"; font-weight: 500; font-style: normal; src: url("../font/SFProText-Medium.ttf"); }
@font-face { font-family: "SFProD"; font-weight: 400; font-style: normal; src: url("../font/SFProDisplay-Regular.ttf"); }

body {
	background-color: #edeef0;

	&::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
		background-color: transparent;
	}
}

.Tappable--ios.Tappable--active:not([disabled]):not(.TabsItem):not(.PanelHeaderButton):not(.Button):not(.PanelHeaderContent__in):not(.ActionSheetItem):not(.Banner__in) {
	background: transparent!important;
}

input, textarea {
	outline: none;
	width: 100%;
	box-sizing: border-box;
	padding: 5px 10px 5px;
	border: 1px solid #d3d9de;
	border-radius: 3px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: -0.08px;
	color: #000000;

	&::placeholder {
		color: #828282;
	}
}

textarea {
	resize: none;
	height: 80px;
	border-color: #c9d0d6;
	border-radius: 2px;
}

.form-group {
	display: flex;
	flex-direction: column;

	> div:not(:last-child) {
		margin-bottom: 20px;
	}
}

.Button, .Radio, .Checkbox {
	cursor: pointer;
}

.Button {
	border-radius: 4px;

	&--sz-m {
		.Button__content {
			font-size: 12.5px;
		}
	}

	&--lvl-primary {
		background-color: #5181b8;
	}

	&--lvl-tertiary {
		color: #2a5885;

		.Button__content {
			font-size: 14px;
		}
	}

	&--lvl-secondary {
		color: #5c6c80;
		background: #e5ebf1;
	}
}

.block-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.ml-a {
	margin-left: auto;
}

.btn-add {
	margin-top: 16px;
	margin-left: 27px;
	margin-bottom: 4px;
	padding-left: 2px;
	padding-right: 2px;

	.Button__before {
		margin-right: 15px;
	}

	.Button__content {
		font-size: 13px;
	}
}

.radio-custom {
	padding: 0;

	&:not(:last-child) {
		margin-bottom: 11px;
	}

	.Radio {

		&__icon {
			margin-right: 8px;
			width: 15px;
			height: 15px;
			border: 1px solid #c1c9d1;
		}

		&__content {
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: -0.08px;
			color: #000000;
		}
	}

	.Radio__input:checked~.Radio__container .Radio__icon {
		border-color: #5181b8;

		&:after {
			width: 7px;
			height: 7px;
			top: 3px;
			left: 3px;
			background: #5181b8;
		}
	}
}

.checkbox-custom {
	padding: 0;

	.Checkbox {

		&__container {
			align-items: center;
		}

		&__icon {
			margin-left: 0;
			margin-right: 8px;
			width: 15px;
			height: 15px;
			border-radius: 3px;
			border: 1px solid #c1c9d1;

			.Icon {
				width: 12px!important;
				height: 12px!important;
			}

			.Icon svg {
				width: 13px!important;
				height: 13px!important;
			}
		}

		&__content {
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.08px;
			color: #000000;
		}

		&__input:checked~.Checkbox__container .Checkbox__icon {
			background-color: #5b88bd;
		}
	}
}
