.form-load-img {
	display: flex;
	width: 100%;

	> div {
		position: relative;
		height: 90px;
		border-radius: 4px;
		background-color: #e6ecf1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	&--add {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg {
			color: rgb(155, 180, 204);
		}
	}

	&--image {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&--avatar {
		margin-right: 15px;
		width: 90px;

		span {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.17;
			letter-spacing: normal;
			text-align: center;
			color: #55677d;
		}
	}

	&--cover {
		flex-grow: 1;
	}

	span {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		text-align: center;
		color: #55677d;
	}

	.Icon--16 {
		position: absolute;
		top: 4px;
		right: 4px;
		opacity: 0.64;
		border-radius: 2px;
		background-color: #000000;
		cursor: pointer;
		z-index: 0;
		color: #fff;
	}
}
