.select {
	width: 100%;
	position: relative;

	&--label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 31px;
		box-sizing: border-box;
		padding-left: 10px;
		padding-right: 10px;
		border: 1px solid #d3d9de;
		border-radius: 3px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.46;
		letter-spacing: -0.08px;
		color: #000000;
		cursor: pointer;
	}

	&--items {
		display: none;
		position: absolute;
		background: #fff;
		width: 100%;
		border: 1px solid #d3d9de;
		border-top: none;
		z-index: 1;
		box-sizing: border-box;
		border-radius: 0 0 3px 3px;
		overflow: hidden;
	}

	&--item {
		display: flex;
		align-items: center;
		height: 25px;
		font-size: 13px;
		line-height: 1.46;
		letter-spacing: -0.08px;
		padding-left: 10px;
		padding-right: 10px;
		cursor: pointer;

		&:hover {
			background: #E5EBF1;
		}
	}

	&--opened {

		.select--items {
			display: block;
		}

		.select--label {
			border-radius: 3px 3px 0 0;
		}
	}
}
