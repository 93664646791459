.dropdown-menu {
	display: flex;
	position: relative;
	padding-top: 5px;
	padding-bottom: 5px;

	&--label {
		display: flex;
		align-items: center;
		cursor: pointer;

		> .Icon {
			min-width: 20px;
			display: flex;
			justify-content: center;
		}
	}

	&--items {
		left: 0;
		transform: translateY(10px);
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-in-out;
		z-index: 5;
		position: absolute;
		top: 100%;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		border: 1px solid #d4dee7;
		background: #fff;
		border-radius: 4px;
		padding-top: 5px;
		padding-bottom: 5px;

		&:before, &:after {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 93px;
			border: solid transparent;
			height: 0;
			width: 0;
			pointer-events: none;
		}

		&:before {
			border-width: 6px;
			margin: 0 -6px;
			border-bottom-color: #d4dee7;
		}

		&:after {
			border-width: 5px;
			margin: 0 -5px;
			border-bottom-color: #fff;
		}
	}

	&--item {
		height: 30px;
		display: flex;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #2a5885;
		text-decoration: none;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			background: #E5EBF1;
		}
	}

	&:hover {

		.dropdown-menu--items {
			transform: translateY(0px);
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
	}

	&.hide {

		.dropdown-menu--items {
			transform: translateY(10px);
			visibility: hidden;
			opacity: 0;
			pointer-events: none;
		}
	}
}
