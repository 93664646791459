.tabs-header {
	position: relative;
	box-shadow: inset 0 -1px 0 0 #e7e8ec;
	width: calc(100% - 40px);
	margin-right: 20px;
	margin-left: 20px;
	border-bottom: none;
	font-family: SFPro;

	.TabsItem {
		flex-grow: 0;
		padding-left: 4px;
		padding-right: 4px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.15px;
		color: #656565;
		cursor: pointer;

		&--selected {
			color: #000;

			&:after {
				background: #5181b8;
			}
		}

		&__in {
			padding: 15px 6px;
		}

		&:after {
			width: calc(100% - 8px);
			left: 4px;
			bottom: 0;
			border-radius: 0;
		}
	}
}

.tabs-inner {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	font-family: SFPro;
	min-height: 292px;

	&.fixed-height {
		min-height: 482px;
	}

	> div:first-child.block-head {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.tabs-header {
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		width: auto;
		box-shadow: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		&:before {
			content: '';
			position: absolute;
			left: 20px;
			right: 20px;
			bottom: 0;
			border: 1px solid #e3e4e8;
			border-top: none;
		}

		.TabsItem {
			padding-left: 4px;
			padding-right: 4px;

			&__in {
				padding: 15px 6px;
			}

			&:after {
				width: calc(100% - 8px);
				left: 4px;
				bottom: 0;
				border-radius: 0;
			}
		}
	}
}

.tabs-offset {
	padding-top: 17px;
	padding-left: 20px;
	padding-right: 20px;
}
